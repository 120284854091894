import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import { Link, Trans } from "gatsby-plugin-react-i18next";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CaretRightIcon,
  CopyIcon,
  FacebookIcon,
  LinkedInIcon,
  RoundArrowLeftIcon,
  TwitterIcon,
} from "../images/Icons";
import { graphql } from "gatsby";
import { copyToClipboard } from "../helpers/functions";
import { Helmet } from "react-helmet";
import { SubscriptionComponent } from "../pages/blog";
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from "react-share"

const Blogpost = ({
  pageContext: { nextPost, page, previousPost },
  location,
}) => {
  const { html } = page?.content
  const [copied, setCopied] = React.useState(false)
  console.log(location.href)
  return (
    <Layout pageTitle={`${page?.title} - Moniepoint Inc.`}>
      <Helmet>
        <meta property="og:type" content="website" />
        <meta property="og:title" content={page?.title + " - " + page?.author} />
        <meta property="og:image" content={page?.coverImage?.url} />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="400" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <BlogTopNavigationContainer>
        <div className="content">
          <div className="breadcrumb-desktop">
            <Link to="/blog" className="breadcrumb-home">
              Home
            </Link>
            <CaretRightIcon size={10} color="#828282" />
            <div className="breadcrumb-current">{page?.title}</div>
          </div>
          <div className="breadcrumb-mobile">
            <Link to="/blog">
              <RoundArrowLeftIcon size={19} />
            </Link>
            <div className="breadcrumb-back-text">
              <Trans>Blog</Trans>
            </div>
          </div>
        </div>
      </BlogTopNavigationContainer>
      <TitleSectionContainer>
        <div className="content">
          {/* <div className="category people">People</div> */}
          <h1 className="title">{page?.title}</h1>
          <p className="description">
            {page?.author && <><span>
              by <span className="author">{page?.author}</span>
            </span>
              <span>|</span></>
            }   <time dateTime={page.date}>{page.date}</time>
          </p>
        </div>
      </TitleSectionContainer>

      <TextContainer>
        <article
          className="content"
        >
          <div className="" dangerouslySetInnerHTML={{ __html: html }}></div>
        </article>
      </TextContainer>
      <ShareSocialsContainer>
        <div className="content">
          <div className="socials">
            <span className="">
              <Trans>Share</Trans>
            </span>
            <FacebookShareButton url={location.href} quote={page?.title}>
              <span className="social-share-link"><FacebookIcon size={20} color="#0361F0" /></span>
            </FacebookShareButton>

            <TwitterShareButton url={location.href} title={page?.title}>
              <span className="social-share-link"><TwitterIcon size={20} color="#0361F0" /></span>
            </TwitterShareButton>

            <LinkedinShareButton url={location.href} title={page?.title}>
              <span className="social-share-link"><LinkedInIcon size={20} color="#0361F0" /></span>
            </LinkedinShareButton>


            {copied ? <div className="copy-button"><p>Copied!</p></div>
              :
              <div className="copy-button" onClick={() => {
                setCopied(true); copyToClipboard(location.href);
                setTimeout(() => {
                  setCopied(false)
                }, 2500);
              }}>
                <Trans>copy_link</Trans>
                <CopyIcon />

              </div>}
          </div>
        </div>
      </ShareSocialsContainer>
      {
        (nextPost || previousPost) && <BlogBottomNavigation>
          <div className="content">
            {previousPost && <Link to={`/blog/${previousPost.slug}`} className="navigation-item left">
              <div className="button-icon">
                <ArrowLeftIcon />
              </div>
              <span>
                {previousPost.title}
              </span>
            </Link>}

            {nextPost && <Link to={`/blog/${nextPost.slug}`} className="navigation-item right">
              <span>
                {nextPost.title}
              </span>
              <div className="button-icon">
                <ArrowRightIcon />
              </div>
            </Link>}
          </div>
        </BlogBottomNavigation>
      }
      <SubscriptionComponent />
    </Layout >
  );
};

export default Blogpost;

export const query = graphql`
fragment AssetFields on GraphCMS_Asset {
    id
    localFile {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  query BlogPostQuery($id: String!, $language: String!) {
    coverImage: graphCmsAsset(
      coverImagePost: { elemMatch: { id: { eq: $id } } }
    ) {
      ...AssetFields
    }
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const BlogTopNavigationContainer = styled.div`
  background-color: white;
  border-bottom: 1px solid rgba(3, 87, 238, 0.2);

  .content {
    max-width: 1440px;
    margin: auto;
    padding: 24px 150px;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 24px 60px;
    }

    .breadcrumb-desktop {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;

      .breadcrumb-home {
        color: rgba(3, 97, 240, 1);
        text-decoration: none;
      }
    }

    .breadcrumb-mobile {
      display: none;
      gap: 16px;
      align-items: center;

      .breadcrumb-back-text {
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    border-bottom: none;
    .content {
      padding: 12px 20px;

      .breadcrumb-desktop {
        display: none;
      }
      .breadcrumb-mobile {
        display: flex;
      }
    }
  }
`;

const TitleSectionContainer = styled.section`
  background-color: white;
  .content {
    max-width: 1440px;
    margin: auto;
    padding: 48px 263px 16px;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 48px 60px 16px;
    }

    .category {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 8px;

      &.all {
        color: rgba(9, 34, 86, 1);
      }
      &.people {
        color: rgba(3, 87, 238, 1);
      }
      &.tech {
        color: rgba(5, 194, 255, 1);
      }
      &.process {
        color: rgba(255, 130, 76, 1);
      }
      &.impact-stories {
        color: rgba(255, 168, 0, 1);
      }
      &.news {
        color: rgba(231, 64, 74, 1);
      }
    }

    .title {
      font-family: "Founders Grotesk";
      font-size: 48px;
      font-weight: 500;
      line-height: 57px;
      letter-spacing: 0em;
      text-align: left;
      max-width: 767px;
      margin-bottom: 16px;
    }

    .description {
      font-family: "Founders Grotesk";
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(0, 0, 0, 0.21);
      display: flex;
      align-items: center;
      gap: 16px;

      .author {
        color: rgba(0, 0, 0, 0.7);
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .content {
      padding: 32px 20px 0px;

      .category {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 8px;
      }
      .title {
        font-size: 32px;
        line-height: 37.76px;
      }

      .description {
        font-size: 16px;
        line-height: 18.88px;
      }
    }
  }
`;


const TextContainer = styled.section`
  margin: 24px 0px;

  .content {
    max-width: 950px;
    padding: 28px 20px;
    margin: auto;
    color: rgba(0, 0, 0, 0.9);
    font-size: 16px;
    font-weight: 400;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: justify;

    h1, h2, h3, h4, h5, h6 {
      font-family: "Founders Grotesk";
      text-align: left;
     &:not(:first-child){
      margin: 32px 0 16px;
     }

     &:first-child{
      margin: 0 0 16px;
     }
     & > *{
        font-family: "Founders Grotesk";
      }


    }

    h1 {
      font-size: 2rem;
      margin-top: 8px;
    }
    h2 {
      font-size: 1.75rem;
      margin-top: 8px;
    }
    h3 {
      font-size: 1.5rem;
    }
    h4 {
      font-size: 1.25rem;
    }

    a {
      text-decoration: none;
      color: #0361F0;
    }

    p , ul{
      margin-bottom: 16px;
    }

    li {
      margin: 8px 0 8px 16px;
    }

    p:has(strong) {
      margin-bottom: 8px;
    }

    .content-image {
      height: 442px;
      border-radius: 16px;
    }

    p > img:not(:only-child) {
      height: 20px;
      width: auto;;
    }

    img+p {
      margin-top: 16px;
    }

    p>em {
      font-weight: 500;
    }

    img {
      height: auto;
      width: 100%;
      border-radius: 8px;
      margin: 16px 0;
    }

    iframe {
      aspect-ratio: 16/9;
    width: 100%;
    height: auto;
    border-radius: 16px;
    margin: 16px 0;
    }
  }

  @media only screen and (max-width: 768px) {
    margin: 16px 0;
    .content {
      padding: 16px 20px;
      font-size: 16px;
      line-height: 25.76px;

      .content-image {
        height: 264px;
      }
    }
  }
`;

const ShareSocialsContainer = styled.section`
  .content {
    max-width: 950px;
    padding: 12px 20px 60px;
    margin: auto;

    .socials {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 24px;
      font-size: 24px;
      font-weight: 500;
      line-height: 31px;
      letter-spacing: 0em;
      text-align: center;
      color: rgba(0, 0, 0, 1);

      .social-share-link {
        cursor: pointer;
      }

      .copy-button {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(3, 97, 240, 1);
        margin-left: 48px;
        cursor: pointer;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .content {
      padding: 12px 20px 24px;
      .socials {
        .copy-button {
          margin-left: auto;
        }
      }
    }
  }
`;

const BlogBottomNavigation = styled.section`
  background-color: white;
  border-top: 1px solid rgba(3, 87, 238, 0.2);

  .content {
    max-width: 1440px;
    margin: auto;
    padding: 48px 150px;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 24px 60px;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    .navigation-item {
      display: flex;
      align-items: center;
      gap: 24px;
      text-decoration: none;

      .button-icon {
        padding: 10px;
        border-radius: 8px;
        height: 44px;
        width: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(3, 87, 238, 0.1);
      }
      span {
        max-width: 358px;
        font-family: "Founders Grotesk";
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
      }

      &.left {
        text-align: left;
        color: rgba(0, 0, 0, 0.7);
      }

      &.right {
        text-align: right;
        color: rgba(0, 0, 0, 1);
      }
    }
  }

  @media only screen and (max-width: 768px) {
    border-top: none;
    .content {
      padding: 24px 20px;

      .navigation-item {
        align-items: flex-start;
        gap: 16px;

        span {
          font-family: "Founders Grotesk";
          font-size: 16px;
          font-weight: 600;
          line-height: 19px;
        }

        .button-icon {
          height: 36px;
          width: 36px;
        }
      }
    }
  }
`;
